<template>
  <div v-if="plan">
    <div class="d-flex mb-16">
      <h1 class="headline--md text-bold text-uppercase">{{ plan.title }} detail</h1>
      <!-- TODO: implement set interest rate -->
      <!-- <router-link
        class="btn btn--primary btn--md"
        tag="button"
        :to="{ name: 'CrowdfundingPlanEdit', params: { programId: paramsRoute.programId, planId: paramsRoute.planId } }"
      >Edit Plan</router-link> -->
    </div>

    <div class="box box--md box--white">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Fund Name</p>
                <h2 class="headline--md mt-4">{{ plan.title }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Status</p>
                <h2 class="headline--md mt-4">Success</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Interest Rate (%)</p>
                <h2 class="headline--md mt-4">{{ plan.rate | rate_percent }}</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Minimum amount</p>
                <h2 class="headline--md mt-4">{{ plan.minDepositAmount | number_with_commas }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Maximum amount</p>
                <h2 class="headline--md mt-4">{{ plan.maxDepositAmount | number_with_commas }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Hold period (Months)</p>
                <h2 class="headline--md mt-4">{{ plan.period }}</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Minimum staked TOKO</p>
                <h2 class="headline--md mt-4">{{ plan.minTokoAmount | number_with_commas }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Status</p>
                <h2 v-if="plan.status === status.active" class="headline--md mt-4 text-success">
                  {{ plan.status | format_status_text }}
                </h2>
                <h2 v-else class="headline--md mt-4 text--gray">{{ plan.status | format_status_text }}</h2>
              </div>
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { STATUS } from '@/utils/key-mapping'

export default {
  data() {
    return {
      status: STATUS
    }
  },

  computed: {
    ...mapState('crowdfunding', ['plan']),

    paramsRoute() {
      const params = this.$route.params

      return {
        programId: params.programId,
        planId: params.planId
      }
    }
  },

  methods: {
    ...mapActions('crowdfunding', ['getCrowdfundingPlan']),

    ...mapMutations('crowdfunding', ['CLEAR_CROWDFUNDING_PLAN'])
  },

  created() {
    this['CLEAR_CROWDFUNDING_PLAN']()
    this.getCrowdfundingPlan(this.paramsRoute)
  }
}
</script>
